import { combineReducers } from 'redux';
import sistemaReducer from './sistema/reducers';
import contaReducer from './conta/reducers';

const rootReducer = combineReducers({
  sistemaReducer,
  contaReducer,
});

export default rootReducer;
