import { TabelaCnpj } from './cabecalhos';
import TabelaPapeis from './cabecalhos/TabelaPapeis';
import { CNPJ, PAPEIS } from './nomesCabecalhos';

export const getTabela = tipo => {
  switch (tipo) {
    case CNPJ:
      return TabelaCnpj;
    case PAPEIS:
      return TabelaPapeis;
    default:
      return {
        titulo: 'Cabeçalho não informado',
      };
  }
};
