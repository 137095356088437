import React from 'react';
import { ContaUsuarioForm } from '../../components/forms';
import { DetalhesContainer } from '../styles';
import { FormContainer } from './styles';

const MinhaConta = () => {
  return (
    <DetalhesContainer>
      <FormContainer>
        <ContaUsuarioForm />
      </FormContainer>
    </DetalhesContainer>
  );
};

export default MinhaConta;
