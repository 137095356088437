import { any, arrayOf, objectOf } from 'prop-types';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Tabela } from '../../layout';
import { CNPJ } from '../../layout/Tabela/nomesCabecalhos';
import { Tabs } from '../styles';

const { TabPane } = Tabs;

const ContratoTab = ({ cnpjs, history }) => {
  const [tabAtiva, setTabAtiva] = useState('cnpj');
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  return (
    <Tabs
      defaultActiveKey={tabAtiva}
      activeKey={tabAtiva}
      onChange={key => {
        setTabAtiva(key);
      }}
    >
      <TabPane tab="CNPJ" key="cnpj">
        <Tabela
          loading={loading}
          cabecalho={CNPJ}
          dados={cnpjs}
          celulaSelecionada={({ uid }) => {
            history.push(`/meus-contratos/cnpj/${uid}`);
          }}
        />
      </TabPane>
    </Tabs>
  );
};

ContratoTab.propTypes = {
  history: objectOf(any).isRequired,
  cnpjs: arrayOf(any),
};

ContratoTab.defaultProps = {
  cnpjs: [],
};

export default ContratoTab;
