import React, { useEffect, useState } from 'react';
import { any, objectOf } from 'prop-types';
import { Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { Card } from '../../components/layout';
import { useApi, useUrl } from '../../hooks';
import { REDIRECT } from '../../hooks/useUrl/acoes';
import { Row, Col, Container, Titulo } from '../styles';
import BarraDePesquisa from '../../components/barraDePesquisa';

const MeusAcessos = ({ history }) => {
  const { buscar, alterar } = useApi();
  const { pegarValorQuery } = useUrl(history);
  const redirect = pegarValorQuery(REDIRECT);
  const [acessos, setAcessos] = useState([]);
  const [pagina, setPagina] = useState(0);
  const [paginacao, setPaginacao] = useState(false);

  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  const { uid: usuarioUid } = useSelector(
    ({ contaReducer }) => contaReducer,
    shallowEqual,
  );

  useEffect(() => {
    const VerificarSuperUsuario = async () => {
      const { data } = await buscar(
        `/usuarios/verificarAdminGlobal/${usuarioUid}`,
      );
      setPaginacao(data);
    };

    VerificarSuperUsuario();
  }, [buscar, usuarioUid]);

  useEffect(() => {
    const buscarAcessos = async () => {
      const { data } = await buscar(
        `/meus-acessos?pagina=${pagina}&tamanho=10`,
      );
      setAcessos(acesso => [...acesso, ...data]);
    };

    buscarAcessos();
  }, [buscar, pagina]);

  useEffect(() => {
    if (!paginacao) {
      return undefined;
    }

    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        setPagina(page => page + 1);
      }
    });

    intersectionObserver.observe(document.querySelector('#final'));
    return () => intersectionObserver.disconnect();
  }, [paginacao]);

  const iniciarSessao = async (papelUsuarioUid, clienteCnpj) => {
    const { data } = await alterar('/sessoes', {
      papelUsuarioUid,
      clienteCnpj,
    });
    if (data) {
      const { token } = data;
      window.location.href = redirect
        ? `${redirect}?token=${token}`
        : `${process.env.REACT_APP_FACCAO_URL}/?token=${token}`;
    }
  };

  return (
    <Container>
      <Spin spinning={loading}>
        <Titulo>Meus Acessos</Titulo>

        <BarraDePesquisa
          onchange={async value => {
            const { data } = await buscar(`/meus-acessos/${value}`);
            setAcessos(data);
            setPagina(0);
            setPaginacao(false);
          }}
          onclear={async () => {
            const { data } = await buscar(
              `/meus-acessos?pagina=${pagina}&tamanho=10`,
            );
            setAcessos(data);
            setPaginacao(true);
          }}
        />

        <Row>
          {acessos?.map(({ cliente, papelUsuario }, index) => (
            <Col key={index}>
              <Card
                onClick={() => iniciarSessao(papelUsuario?.uid, cliente?.cnpj)}
                header={cliente?.razaoSocial}
                content={
                  <>
                    <p>CNPJ {cliente?.cnpj}</p>
                    <p>{papelUsuario?.cargo}</p>
                  </>
                }
              />
            </Col>
          ))}
          {!acessos.length ? <h5>Nenhum acesso localizado</h5> : null}
          {paginacao ? <div id="final" /> : null}
        </Row>
      </Spin>
    </Container>
  );
};

MeusAcessos.propTypes = {
  history: objectOf(any).isRequired,
};

export default MeusAcessos;
