import styled from 'styled-components';
import { getImagens } from '../../../assets/background';
import logoClara from '../../../assets/logobh.svg';
import logoEscura from '../../../assets/logoh.svg';

const corPrimaria = '#549c6e';
const imagens = getImagens();
const { img: imagem, escuro } = imagens[
  Math.floor(Math.random() * (imagens.length - 1))
];
const logo = escuro ? logoClara : logoEscura;

export const Card = styled.div`
  background-color: rgb(238 236 234 /50%);
  border-radius: 6px;
  box-shadow: 0px 0px 15px -5px rgb(0 0 0 /30%);
  @media only screen and (max-device-width: 640px) {
    width: 95%;
  }

  button.link {
    color: ${escuro ? '#fff' : '#000'};
  }
`;

export const TituloContainer = styled.div`
  border-bottom: 1px solid rgb(196, 196, 196);
  padding: 15px;
  text-align: center;
  background: white;
  border-radius: 6px 6px 0px 0px;
  span {
    font-size: 25px;
    @media only screen and (max-device-width: 640px) {
      font-size: 16px;
    }
  }
`;

export const FormContainer = styled.div`
  padding: 40px 15px 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  backdrop-filter: blur(15px);
  border-radius: 0 0 6px 6px;
`;

export const TextContainer = styled.div`
  margin-left: 15px;
  color: #fff;
  @media only screen and (max-device-width: 640px) {
    height: 45px;
  }
`;

export const LogoContainer = styled.div`
  position: fixed;
  top: 20px;
  @media only screen and (max-device-width: 640px) {
    width: 20%;
    height: 10%;
  }
  height: 7%;
  width: 14%;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Footer = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 45px;
  align-items: center;
`;

export const Link = styled.a`
  color: #f7a445;
`;

export const LinksContainer = styled.div`
  margin: 0px 15px;
  display: flex;
  justify-content: space-between;
  width: 250px;
`;

export const Body = styled.div`
  padding: 20px;
  @media only screen and (max-device-width: 640px) {
    padding: 5px;
  }
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 300px;
  width: 100%;
`;

export const Container = styled.div`
  background-image: url(${imagem});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  max-width: 100%;

  img {
    height: auto;
    width: 100%;
  }

  button {
    background-color: ${corPrimaria};
    border: none;
    border-radius: 3px;
    cursor: pointer;
    height: 40px;
    margin: 0.5rem;
    width: 300px;
    @media only screen and (max-device-width: 320px) {
      margin: 0px;
    }
    @media only screen and (max-device-width: 640px) {
      margin: 0px;
      width: 100%;
    }

    &:hover {
      transition: 0.5s;
    }
  }

  input {
    background: #fafafa;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    height: 40px;
    margin: 0 0.5rem 0.5rem 0.5rem;
    padding: 1rem;
    width: 300px;
    @media only screen and (max-device-width: 320px) {
      margin: 0px;
    }
    @media only screen and (max-device-width: 640px) {
      margin: 0px;
      width: 100%;
    }

    &::placeholder {
      font-family: 'Source Sans Pro', sans-serif;
    }
  }

  label {
    color: #555;
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
    padding-left: 0.5rem;
    text-align: left;
  }
`;
