import styled from 'styled-components';
import { Row as AntRow, Col as AntCol, Input } from 'antd';
import colors from '../../styles/colors';

export const Container = styled.div`
  ${props => (!props.ativo ? 'display: none' : null)}
`;

export const Col = styled(AntCol)`
  margin-bottom: 10px;
`;

export const Row = styled(AntRow)`
  justify-content: center;
  @media only screen and (min-device-width: 640px) {
    ${Col}:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const Campo = styled(Input)`
  border-radius: 10px;
  text-align: center;

  margin: 0 !important;
  margin-bottom: 10px !important;
  width: 300px;

  &:hover {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }

  &:focus {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }

  @media only screen and (max-width: 640px) {
    width: 240px;
    &:focus {
      border-color: ${colors.secundaria};
      box-shadow: 0 0 0 2px ${colors.secundaria};
    }
  }
`;
