import React, { useEffect, useState } from 'react';
import { Drawer, Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { any, objectOf } from 'prop-types';
import { Card } from '../../components/layout';
import { useApi, useUrl } from '../../hooks';
import { Row, Col, Container, Titulo } from '../styles';
import { CADASTRAR, CONVITE } from '../../hooks/useUrl/acoes';
import { ConvidarUsuarioForm } from '../../components/forms';
import BarraDePesquisa from '../../components/barraDePesquisa';

const Indicar = ({ history }) => {
  const { buscar } = useApi();
  const { existeQuery, removerQuery, adicionarQuery } = useUrl(history);
  const [acessos, setAcessos] = useState([]);
  const [acessoSelecionado, setAcessoSelecionado] = useState(null);
  const abrirForm = existeQuery(CADASTRAR, CONVITE);
  const [pagina, setPagina] = useState(0);
  const [paginacao, setPaginacao] = useState(false);
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  const { uid: usuarioUid } = useSelector(
    ({ contaReducer }) => contaReducer,
    shallowEqual,
  );

  useEffect(() => {
    const VerificarSuperUsuario = async () => {
      const { data } = await buscar(
        `/usuarios/verificarAdminGlobal/${usuarioUid}`,
      );
      setPaginacao(data);
    };

    VerificarSuperUsuario();
  }, [buscar, usuarioUid]);

  useEffect(() => {
    const buscarAcessos = async () => {
      const { data } = await buscar(
        `/meus-contratos?tamanho=10&pagina=${pagina}`,
      );
      setAcessos(acesso => [...acesso, ...data]);
    };
    buscarAcessos();
  }, [buscar, pagina]);

  useEffect(() => {
    if (!paginacao) {
      return undefined;
    }

    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        setPagina(page => page + 1);
      }
    });

    intersectionObserver.observe(document.querySelector('#final'));
    return () => intersectionObserver.disconnect();
  }, [paginacao]);

  return (
    <Container>
      <Spin spinning={loading}>
        <Titulo>Indicar o Sistema</Titulo>
        <BarraDePesquisa
          onchange={async value => {
            const { data } = await buscar(`/meus-contratos/${value}`);
            setPagina(0);
            setAcessos(data);
            setPaginacao(false);
          }}
          onclear={async () => {
            const { data } = await buscar(
              `/meus-contratos?tamanho=10&pagina=${pagina}`,
            );
            setAcessos(data);
            setPaginacao(true);
          }}
        />
        <Row>
          {acessos?.map(({ cliente, papel, ...resto }, index) => (
            <Col key={index}>
              <Card
                header={cliente?.razaoSocial}
                content={
                  <>
                    <p>CNPJ {cliente?.cnpj}</p>
                    <p>{papel?.nome}</p>
                  </>
                }
                button={{
                  text: 'Indicar',
                  onClick: () => {
                    setAcessoSelecionado({ cliente, papel, ...resto });
                    adicionarQuery(CADASTRAR, CONVITE);
                  },
                }}
              />
            </Col>
          ))}
          {!acessos.length ? <h5>Nenhum contrato localizado</h5> : null}
          {paginacao ? <div id="final" /> : null}
        </Row>
      </Spin>

      <Drawer
        title="Indicar Sistema"
        width={window.screen.width >= 640 ? '320px' : '100%'}
        closable
        visible={abrirForm}
        onClose={() => {
          setAcessoSelecionado(null);
          removerQuery(CADASTRAR, CONVITE);
        }}
      >
        <ConvidarUsuarioForm
          indicacao
          acesso={acessoSelecionado}
          afterSubmit={data => {
            if (data) {
              setAcessoSelecionado(null);
              removerQuery(CADASTRAR, CONVITE);
            }
          }}
        />
      </Drawer>
    </Container>
  );
};

Indicar.propTypes = {
  history: objectOf(any).isRequired,
};

export default Indicar;
