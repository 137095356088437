import React from 'react';
import { any, bool, func, objectOf } from 'prop-types';
import { Form, Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { onFinish } from '../functions';
import { Campo } from '../../layout';
import { useApi } from '../../../hooks';
import { Button, InputFormulario } from '../styles';

const ConvidarUsuarioForm = ({ afterSubmit, acesso, indicacao }) => {
  const [form] = Form.useForm();
  const { criar } = useApi();
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  const cadastrar = async values => {
    if (indicacao) {
      const { data } = await criar(
        `/indicar/${acesso?.uid}`,
        {
          papelUid: acesso?.sistemaUid,
          cnpj: acesso?.cliente?.cnpj,
          ...values,
        },
        'Usuário indicado com sucesso',
      );
      return data;
    }
    const { data } = await criar(
      '/convites/convidar',
      {
        papelUid: acesso?.sistemaUid,
        cnpj: acesso?.cliente?.cnpj,
        ...values,
      },
      'Usuário convidado com sucesso',
    );
    return data;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      hideRequiredMark
      onFinish={values => {
        onFinish(values, cadastrar, null, afterSubmit, form.resetFields);
      }}
    >
      <Spin spinning={loading}>
        <Campo label="Nome" name="nome" fluido obrigatorio>
          <InputFormulario
            type="text"
            placeholder="Digite o nome"
            maxLength={50}
          />
        </Campo>
        <Campo label="Sobrenome" name="sobrenome" fluido obrigatorio>
          <InputFormulario
            type="text"
            placeholder="Digite o sobrenome"
            maxLength={50}
          />
        </Campo>
        <Campo label="Email" name="email" fluido obrigatorio>
          <InputFormulario
            type="email"
            placeholder="cliente@vexta.com.br"
            maxLength={50}
          />
        </Campo>

        <Button htmlType="submit" type="primary">
          {indicacao ? 'Indicar' : 'Convidar'}
        </Button>
      </Spin>
    </Form>
  );
};

ConvidarUsuarioForm.propTypes = {
  acesso: objectOf(any),
  afterSubmit: func,
  indicacao: bool,
};

ConvidarUsuarioForm.defaultProps = {
  afterSubmit: null,
  acesso: null,
  indicacao: null,
};

export default ConvidarUsuarioForm;
