import styled from 'styled-components';
import { PageHeader as AntHeader } from 'antd';

export const Classe = styled.span`
  font-weight: normal;
  margin-right: 12px;
`;

export const PageHeader = styled(AntHeader)`
  .ant-tag {
    margin-left: 10px;
  }
`;
