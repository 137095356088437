import {
  SET_CONTA_ATIVA,
  SET_EMAIL,
  SET_NOME,
  SET_SOBRENOME,
  SET_AVATAR_URL,
  SET_UID,
} from './operacoes';

export const setContaUid = payload => {
  return { type: SET_UID, payload };
};

export const setContaAtiva = payload => {
  return { type: SET_CONTA_ATIVA, payload };
};

export const setContaEmail = payload => {
  return { type: SET_EMAIL, payload };
};

export const setContaNome = payload => {
  return { type: SET_NOME, payload };
};

export const setContaSobrenome = payload => {
  return { type: SET_SOBRENOME, payload };
};

export const setContaAvatar = payload => {
  return { type: SET_AVATAR_URL, payload };
};
