import React from 'react';
import { elementType, func, object, oneOfType, string } from 'prop-types';
import { Classe, PageHeader } from './styles';

const Header = ({ children, titulo, subTitulo, classe, ...props }) => {
  return (
    <PageHeader
      ghost={false}
      title={
        <>
          <Classe>{classe}</Classe>
          {typeof titulo === 'function' ? titulo?.() : titulo}
        </>
      }
      subTitle={typeof subTitulo === 'function' ? subTitulo?.() : subTitulo}
      {...props}
    >
      {children}
    </PageHeader>
  );
};

Header.propTypes = {
  children: oneOfType([elementType, object]),
  titulo: oneOfType([string, func]),
  subTitulo: oneOfType([string, func]),
  classe: string,
};

Header.defaultProps = {
  children: null,
  titulo: null,
  subTitulo: null,
  classe: null,
};

export default Header;
