import {
  MinhaConta,
  DetalhesContrato,
  MeusContratos,
  MeusAcessos,
  Contratar,
  DetalhesCnpj,
  Indicar,
  Convidar,
} from '../pages';

export default [
  { path: '/minha-conta', component: MinhaConta },
  { path: '/indicar', component: Indicar },
  { path: '/convidar', component: Convidar, exact: true },
  { path: '/contratar', component: Contratar, exact: true },
  { path: '/meus-acessos', component: MeusAcessos, exact: true },
  { path: '/meus-contratos', component: MeusContratos, exact: true },
  { path: '/meus-contratos/:id', component: DetalhesContrato, exact: true },
  {
    path: '/meus-contratos/cnpj/:id',
    component: DetalhesCnpj,
    exact: true,
  },
];
