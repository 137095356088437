import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { parse } from 'query-string';
import { Menu } from '../components/layout';
import privadas from './privadas';
import publicas from './publicas';
import { setToken, setTokenValido } from '../store/modules/sistema/actions';
import { api } from '../services/api';

const Routes = () => {
  const dispatch = useDispatch();
  const { token, tokenValido } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  const localToken =
    localStorage.getItem('token') === 'undefined' ||
    localStorage.getItem('token') === 'null'
      ? undefined
      : localStorage.getItem('token');

  useEffect(() => {
    if (!localToken && token) {
      localStorage.setItem('token', token);
      api.setToken(token);
    } else if (localToken && !token) {
      dispatch(setToken(localToken));
      api.setToken(localToken);
    } else if (localToken && token) {
      localStorage.setItem('token', token);
      api.setToken(token);
    }
  }, [token, dispatch, localToken]);

  return (
    <Router>
      <Switch>
        {publicas.map(({ path, component, exact }, index) => (
          <Route key={index} path={path} component={component} exact={exact} />
        ))}
        {token && tokenValido === true && (
          <Menu visivel={token && tokenValido === true}>
            {privadas.map(({ path, component, exact }, index) => (
              <Route
                key={index}
                path={path}
                component={component}
                exact={exact}
              />
            ))}
          </Menu>
        )}
        <Route
          render={({ history }) => {
            localStorage.setItem('token', null);
            dispatch(setToken(null));
            dispatch(setTokenValido(false));
            const redirecionamento = parse(window.location.search)?.redirect;
            history.push(
              redirecionamento ? `/?redirect=${redirecionamento}` : '/',
            );
          }}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
