import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import { Spin } from 'antd';
import { useApi } from '../../hooks';
import { Fundo, Botao, ContainerButton, Titulo } from './styles';

const ReenviarConfirmacao = ({ history }) => {
  const { criar } = useApi();
  const { email } = useSelector(
    ({ contaReducer }) => contaReducer,
    shallowEqual,
  );
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  const reenviarConfirmacao = async () => {
    const { data } = await criar(
      '/usuarios/reenviar-confirmacao',
      {
        email,
      },
      'Email de confirmação de conta reenviado.',
    );
    if (data) {
      history.push('/');
    }
  };

  return (
    <Fundo>
      <Spin spinning={loading}>
        <ContainerButton>
          <Titulo>Você ainda não ativou sua conta</Titulo>
          <Botao type="button" onClick={reenviarConfirmacao}>
            Reenviar email de confirmação
          </Botao>
        </ContainerButton>
      </Spin>
    </Fundo>
  );
};

ReenviarConfirmacao.propTypes = {
  history: objectOf(any).isRequired,
};

export default ReenviarConfirmacao;
