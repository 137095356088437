import styled from 'styled-components';
import { Button as AntButton, Table as AntTable } from 'antd';

export const Button = styled(AntButton)`
  width: 50px;
  height: 40px;
  font-size: 20px;
  border: none;
  background: transparent;
  box-shadow: none;
  &:hover {
    background: transparent;
    color: #000000d9;
  }
`;

export const AtributoAgrupado = styled.div`
  margin-left: 50px;
  min-height: 22px;
`;

export const Table = styled(AntTable)`
  .ant-table-cell {
    height: 60px;
    background: transparent;
    padding: 0px !important;
  }

  th.ant-table-cell {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .ant-table-row-expand-icon-cell {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .verde {
    background-color: #f6ffed !important;
  }

  .amarelo {
    background-color: #feffe6 !important;
  }
`;

export const ButtonContainer = styled.div`
  width: inherit;
  margin: 0px;
  display: flex;
  justify-content: ${({ numerobotoes }) =>
    numerobotoes > 1 ? 'flex-end' : 'center'};
  ${Button}:not(:last-child) {
    margin-right: 5px;
  }
`;

export const CellContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ background }) => background};
  padding: 5px 8px 3px;
  display: ${({ agrupar, componente }) => {
    if (componente) {
      return 'contents';
    }
    return !agrupar && 'flex';
  }};
`;

export const Cell = styled.button`
  color: ${({ valorMonetario, texto, cor }) => {
    if (cor) {
      return cor;
    }
    if (valorMonetario) {
      return String(texto).indexOf('-') === -1 ? '#1890ff' : '#cf1322';
    }
    return 'inherit';
  }};
  text-align: ${({ align }) => align || 'center'};
  white-space: nowrap;
  font-weight: inherit;
  background: transparent;
  width: 100%;
  border: none;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  &:focus,
  &:active {
    outline: none;
  }
`;

export const ArrayContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  span:not(:last-child) {
    margin-right: 10px;
  }
`;
