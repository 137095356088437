import { any, objectOf } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Header } from '../../../components/layout';
import Descritor from '../../../components/layout/Descritor';
import maps from '../../../assets/icones/google-maps.png';
import { useApi, useUrl } from '../../../hooks';
import { Botao, EnderecoContainer } from './styles';
import { CADASTRAR, CONTRATO } from '../../../hooks/useUrl/acoes';
import { ContratoForm } from '../../../components/forms';
import { ContratoTab } from '../../../components/tabs';
import { montarHref } from '../../../utils';
import { Drawer } from '../../../styles/global';

/* eslint-disable react/prop-types */
const detalhes = [
  {
    titulo: 'Dados de Identificação',
    itens: [
      { nome: 'CNPJ', propriedade: 'cnpj' },
      { nome: 'Razão Social', propriedade: 'razaoSocial' },
      { nome: 'Nome Fantasia', propriedade: 'nomeFantasia' },
    ],
  },

  {
    titulo: 'Contatos',
    naoTemSe: ({ celular, telefone, email }) => !celular && !telefone && !email,
    itens: [
      {
        nome: 'Celular',
        propriedade: 'celular',
      },
      {
        nome: 'Telefone',
        propriedade: 'telefone',
      },
      {
        nome: 'E-mail',
        custom: ({ email }) => email && <a href={`mailto:${email}`}>{email}</a>,
      },
    ],
  },

  {
    titulo: 'Endereço',
    itens: [
      { nome: 'Município', propriedade: 'municipio' },
      {
        nome: ({
          enderecoLogradouro: logradouro,
          enderecoNumero: numero,
          enderecoComplemento: complemento,
          enderecoBairro: bairro,
          enderecoCep: cep,
          municipio,
        }) => (
          <EnderecoContainer>
            <h4>Endereço</h4>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://www.google.com/maps/search/${montarHref(
                logradouro,
              )}${montarHref(numero)}${montarHref(complemento)}${montarHref(
                bairro,
              )}${montarHref(municipio)}${montarHref(cep)}`}
            >
              Abrir no Google Maps
            </a>
            <img src={maps} alt="Google Maps" />
          </EnderecoContainer>
        ),
        custom: ({
          enderecoLogradouro: logradouro,
          enderecoNumero: numero,
          enderecoComplemento: complemento,
          enderecoBairro: bairro,
          enderecoCep: cep,
        }) => (
          <>
            {`${logradouro}, ${numero || ''}, ${complemento || ''} ${bairro}`}
            <br />
            CEP {cep}
          </>
        ),
      },
    ],
  },
];
/* eslint-enable react/prop-types */

const DetalhesContrato = ({ history, match }) => {
  const { id } = match.params;
  const { buscar } = useApi();
  const { adicionarQuery, removerQuery, existeQuery } = useUrl(history);
  const [contrato, setContrato] = useState(null);
  const abrirForm = existeQuery(CADASTRAR, CONTRATO);
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  useEffect(() => {
    const carregarAcesso = async () => {
      const { data } = await buscar(`/contratos/${id}`);
      setContrato(data);
    };
    carregarAcesso();
  }, [buscar, id]);

  return (
    <>
      <Header
        onBack={() => history.goBack()}
        titulo={contrato?.sistema?.nome}
        classe="Contrato"
        extra={[
          <Botao
            disabled={loading}
            onClick={() => adicionarQuery(CADASTRAR, CONTRATO)}
            key="1"
            type="primary"
          >
            Alterar Cliente
          </Botao>,
        ]}
      >
        <Descritor objeto={contrato?.cliente} propriedades={detalhes} />
      </Header>

      <ContratoTab cnpjs={contrato?.cnpjs} history={history} />

      <Drawer
        title="Alterar Cliente"
        width={window.screen.width >= 640 ? '420px' : '100%'}
        onClose={() => removerQuery(CADASTRAR, CONTRATO)}
        closable
        visible={abrirForm}
      >
        <ContratoForm
          contrato={contrato}
          afterSubmit={cliente => {
            if (cliente) {
              setContrato({ ...contrato, cliente });
              removerQuery(CADASTRAR, CONTRATO);
            }
          }}
        />
      </Drawer>
    </>
  );
};

DetalhesContrato.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default DetalhesContrato;
