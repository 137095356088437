import React from 'react';
import { string, arrayOf, any, oneOfType, objectOf } from 'prop-types';
import { SectionContainer, Titulo, ChildrenContainer } from './styles';

const Sessao = ({ titulo, semMargem, children }) => {
  return (
    <SectionContainer>
      {titulo && <Titulo>{titulo}</Titulo>}
      <ChildrenContainer semMargem={semMargem} titulo={titulo}>
        {children}
      </ChildrenContainer>
    </SectionContainer>
  );
};

Sessao.propTypes = {
  titulo: string,
  semMargem: string,
  children: oneOfType(arrayOf(any), objectOf(any)),
};

Sessao.defaultProps = {
  titulo: '',
  semMargem: 'false',
  children: null,
};

export default Sessao;
