import {
  SET_CONTA_ATIVA,
  SET_EMAIL,
  SET_AVATAR_URL,
  SET_NOME,
  SET_SOBRENOME,
  SET_UID,
} from './operacoes';

const initialState = {
  uid: null,
  ativa: true,
  email: null,
  nome: null,
  sobrenome: null,
  avatarUrl: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UID:
      return {
        ...state,
        uid: action.payload,
      };
    case SET_CONTA_ATIVA:
      return {
        ...state,
        ativa: action.payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_NOME:
      return {
        ...state,
        nome: action.payload,
      };
    case SET_SOBRENOME:
      return {
        ...state,
        sobrenome: action.payload,
      };
    case SET_AVATAR_URL:
      return {
        ...state,
        avatarUrl: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
