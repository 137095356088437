import styled from 'styled-components';
import { List as AntList, Button as AntButton } from 'antd';
import colors from '../../../styles/colors';

export const DataContainer = styled.div`
  height: 80%;
`;

export const Logo = styled.div`
  height: 100px;
  background-image: url('https://vexta.com.br/storage/1578431213-M9LWKWAMOC-3RICGBWULS/conf_logo_site.png?v=20200107180653');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin-bottom: 30px;
`;

export const ItemContainer = styled.div`
  cursor: ${({ pointer }) => pointer === 'true' && 'pointer'};
  display: flex;
  background-color: #fff;
  height: 100%;
  display: block;
  border: 1px solid;
  border-radius: 5px;
  border-color: #f0f0f0;
  padding: 15px;
  width: 300px;

  transition: 0.5s border-color;

  &:hover {
    border-color: ${colors.primaria};
  }

  @media only screen and (max-device-width: 640px) {
    width: 250px;
  }
  @media only screen and (max-device-width: 320px) {
    width: 200px;
  }
`;

export const Item = styled(AntList.Item)`
  border: none !important;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  display: block;

  p {
    margin: 0px;
  }
  p,
  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Button = styled(AntButton)`
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;

  background-color: ${colors.primaria};
  color: ${colors.texto};
  border-color: ${colors.primaria};

  &:hover {
    color: ${colors.primaria};
    border-color: ${colors.primaria};
    background-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.primaria};
    background-color: ${colors.primaria};
  }
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  span {
    font-size: 13px;
    color: #000;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 120%;
    width: 100%;
  }

  h2,
  p,
  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
