import React from 'react';
import { Spin } from 'antd';
import { objectOf, any } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Campo } from '../../components/layout';
import { useApi } from '../../hooks';
import { Form } from '../../components/forms/styles';

import { BlocoDireito, BlocoEsquerdo, Fundo, H2, Imagem } from '../styles';
import { Botao, Input } from './styled';
import logo from '../../assets/logo/horizontal-s-tag2.png';
import { Desktop } from '../../utils/tamanhoDeTela';

const RecuperarSenha = ({ history }) => {
  const [form] = Form.useForm();
  const { criar } = useApi();
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  const esqueceuSenha = async () => {
    const email = form.getFieldValue('email');
    const { data } = await criar(
      '/usuarios/esqueceu',
      {
        email,
      },
      'Email de confirmação da senha enviado.',
    );
    if (data) {
      history.goBack();
    }
  };

  return (
    // <ImageContainer titulo="Recupere sua senha">
    //   <Form
    //     onFinish={esqueceuSenha}
    //     form={form}
    //     layout="vertical"
    //     hideRequiredMark
    //     name="controlled-form"
    //   >
    //     <Spin spinning={loading}>
    //       <Campo fluido obrigatorio name="email" label="SEU E-MAIL">
    //         <Input type="text" placeholder="Digite seu e-mail" />
    //       </Campo>

    //       <Campo fluido>
    //         <button type="submit">Enviar no meu e-mail</button>
    //       </Campo>
    //     </Spin>
    //   </Form>
    // </ImageContainer>
    <>
      {Desktop() ? (
        <>
          <Fundo>
            <BlocoEsquerdo>
              <Imagem src={logo} />
            </BlocoEsquerdo>
            <BlocoDireito>
              <H2>Recupere sua senha</H2>
              <Form
                onFinish={esqueceuSenha}
                form={form}
                layout="vertical"
                hideRequiredMark
                name="controlled-form"
              >
                <Spin spinning={loading}>
                  <Campo fluido obrigatorio name="email" label="SEU E-MAIL">
                    <Input type="text" placeholder="Digite seu e-mail" />
                  </Campo>

                  <Campo fluido>
                    <Botao type="submit" htmlType="submit">
                      Enviar no meu e-mail
                    </Botao>
                  </Campo>
                </Spin>
              </Form>
            </BlocoDireito>
          </Fundo>
        </>
      ) : (
        <>
          <Fundo>
            <Imagem src={logo} />
            <H2>Recupere sua senha</H2>
            <Form
              onFinish={esqueceuSenha}
              form={form}
              layout="vertical"
              hideRequiredMark
              name="controlled-form"
            >
              <Spin spinning={loading}>
                <Campo fluido obrigatorio name="email" label="SEU E-MAIL">
                  <Input type="text" placeholder="Digite seu e-mail" />
                </Campo>

                <Campo fluido>
                  <Botao type="submit" htmlType="submit">
                    Enviar no meu e-mail
                  </Botao>
                </Campo>
              </Spin>
            </Form>
          </Fundo>
        </>
      )}
    </>
  );
};

RecuperarSenha.propTypes = {
  history: objectOf(any).isRequired,
};

export default RecuperarSenha;
