import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { any, objectOf } from 'prop-types';
import { Spin } from 'antd';
import { Card } from '../../components/layout';
import { useApi } from '../../hooks';
import { Row, Col, Container, Titulo } from '../styles';

import BarraDePesquisa from '../../components/barraDePesquisa';

const MeusContratos = ({ history }) => {
  const { buscar } = useApi();
  const [contratos, setContratos] = useState([]);
  const [pagina, setPagina] = useState(0);
  const [paginacao, setPaginacao] = useState(false);
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  const { uid: usuarioUid } = useSelector(
    ({ contaReducer }) => contaReducer,
    shallowEqual,
  );

  useEffect(() => {
    const VerificarSuperUsuario = async () => {
      const { data } = await buscar(
        `/usuarios/verificarAdminGlobal/${usuarioUid}`,
      );
      setPaginacao(data);
    };

    VerificarSuperUsuario();
  }, [buscar, usuarioUid]);

  useEffect(() => {
    const buscarContratos = async () => {
      const { data } = await buscar(
        `/meus-contratos?tamanho=10&pagina=${pagina}`,
      );
      setContratos(contrato => [...contrato, ...data]);
    };

    buscarContratos();
  }, [buscar, pagina]);

  useEffect(() => {
    if (!paginacao) {
      return undefined;
    }

    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        setPagina(page => page + 1);
      }
    });

    intersectionObserver.observe(document.querySelector('#final'));
    return () => intersectionObserver.disconnect();
  }, [paginacao]);

  return (
    <Container>
      <Spin spinning={loading}>
        <Titulo>Meus Contratos</Titulo>

        <BarraDePesquisa
          onchange={async valeu => {
            const { data } = await buscar(`/meus-contratos/${valeu}`);
            setContratos(data);
            setPaginacao(false);
            setPagina(0);
          }}
          onclear={async () => {
            const { data } = await buscar(
              `/meus-contratos?tamanho=10&pagina=${pagina}`,
            );
            setContratos(data);
            setPaginacao(true);
          }}
        />
        <Row>
          {contratos?.map(({ cliente, papel, uid }, index) => (
            <Col key={index}>
              <Card
                header={cliente?.razaoSocial}
                content={
                  <>
                    <p>CNPJ {cliente?.cnpj}</p>
                    <p>{papel?.nome}</p>
                  </>
                }
                button={{
                  text: 'Visualizar',
                  onClick: () => history.push(`/meus-contratos/${uid}`),
                }}
              />
            </Col>
          ))}
          {!contratos.length ? <h5>Nenhum contrato localizado</h5> : null}
          {paginacao ? <div id="final" /> : null}
        </Row>
      </Spin>
    </Container>
  );
};

MeusContratos.propTypes = {
  history: objectOf(any).isRequired,
};

export default MeusContratos;
