import moment from 'moment';
import { transform, isEqual, isObject, isArray } from 'lodash';
import { MesesAno } from './enum';

export const formatar = (tipo, valor, enumerador) => {
  switch (tipo) {
    case 'data':
      return moment(valor, 'YYYY-MM-DD').format('DD/MM/YYYY');
    case 'mes-dia':
      const data = moment(valor);
      const mes = MesesAno[data.month() + 1];
      const dia = data.date() < 10 ? `0${data.date()}` : data.date();
      return `${dia}/${mes.substr(0, 3)}`;
    case 'mes':
      const dataMes = moment(valor);
      return MesesAno[dataMes.month() + 1];
    case 'dia':
      const dataValor = moment(valor);
      return dataValor.date() < 10
        ? `0${dataValor.date()}`
        : String(dataValor.date());
    case 'moeda':
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(valor);
    case 'moeda-compacta':
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
        .format(valor)
        .replace('R$', '');
    case 'decimal':
      return Math.round(Number(Number(valor) + Number.EPSILON) * 100) / 100;
    case 'inteiro':
      return Math.round(Number(valor));
    case 'minutos':
      return `${
        Math.round(Number(Number(valor) + Number.EPSILON) * 100) / 100
      } min`;
    case 'enum':
      return enumerador?.[valor];
    case 'minutosCheio':
      return `${String(valor).replaceAll('.', ',')} min`;
    default:
      return valor;
  }
};

export const formatarData = (data, formato) => {
  if (data && formato) {
    return data.format(formato);
  }
  return undefined;
};

export const montarHref = texto => {
  if (texto && String(texto).length > 0) {
    return `${String(texto).replaceAll(' ', '+')},`;
  }
  return '';
};

export const dataNoIntervalo = (objeto, key, data) => {
  const { dataFinal, dataInicio } = data;
  return (
    moment(objeto[key]).isBetween(dataInicio, dataFinal) ||
    (moment(objeto[key]).isSame(dataFinal) &&
      moment(objeto[key]).isSame(dataInicio))
  );
};

export const removerValorFalso = obj => {
  const newObj = {};
  Object.keys(obj).forEach(prop => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const getDiferencaObjeto = (objetoNovo, objetoAntigo) => {
  let arrayIndexCounter = 0;
  return transform(objetoNovo, (result, value, key) => {
    if (!isEqual(value, objetoAntigo?.[key])) {
      const resultKey = isArray(objetoAntigo) ? arrayIndexCounter++ : key;
      result[resultKey] =
        isObject(value) && isObject(objetoAntigo?.[key])
          ? getDiferencaObjeto(value, objetoAntigo?.[key])
          : value;
    }
  });
};
