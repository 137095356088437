import React, { useEffect, useState } from 'react';
import { any, objectOf } from 'prop-types';
import { Drawer, Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { Card } from '../../components/layout';
import { useApi, useUrl } from '../../hooks';
import { Row, Col, Container, Titulo } from '../styles';
import { ContratoForm } from '../../components/forms';
import { CADASTRAR, CONTRATO } from '../../hooks/useUrl/acoes';
import { Header } from './styles';

const Contratar = ({ history }) => {
  const { buscar } = useApi();
  const { adicionarQuery, removerQuery, existeQuery } = useUrl(history);
  const [contratos, setContratos] = useState([]);
  const [contrato, setContrato] = useState(null);
  const abrirForm = existeQuery(CADASTRAR, CONTRATO);
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  useEffect(() => {
    const buscarContratos = async () => {
      const { data } = await buscar('/sistemas');
      setContratos(data);
    };

    buscarContratos();
  }, [buscar]);

  return (
    <Container>
      <Spin spinning={loading}>
        <Titulo>Contratar um Sistema</Titulo>
        <Row>
          {contratos?.map(({ nome, uid }, index) => (
            <Col key={index}>
              <Card
                header={<Header>{nome}</Header>}
                button={{
                  text: 'Contratar',
                  onClick: () => {
                    setContrato({ uid });
                    adicionarQuery(CADASTRAR, CONTRATO);
                  },
                }}
              />
            </Col>
          ))}
        </Row>
      </Spin>

      <Drawer
        title="Contratar um Sistema"
        width={window.screen.width >= 640 ? '420px' : '100%'}
        onClose={() => removerQuery(CADASTRAR, CONTRATO)}
        closable
        visible={abrirForm}
      >
        <ContratoForm
          contrato={contrato}
          afterSubmit={data => {
            if (data) {
              removerQuery(CADASTRAR, CONTRATO);
            }
          }}
        />
      </Drawer>
    </Container>
  );
};

Contratar.propTypes = {
  history: objectOf(any).isRequired,
};

export default Contratar;
