import {
  ReenviarConfirmacao,
  RedefinirSenha,
  ConfirmarConta,
  Cadastrar,
  AceitarConvite,
  Login,
  RecuperarSenha,
} from '../pages';

export default [
  { path: '/reenviar-confirmacao', component: ReenviarConfirmacao },
  { path: '/concluir-cadastro', component: AceitarConvite },
  { path: '/redefinir-senha', component: RedefinirSenha },
  { path: '/confirmar-conta', component: ConfirmarConta },
  { path: '/esqueci-minha-senha', component: RecuperarSenha },
  { path: '/cadastrar', component: Cadastrar },
  { path: '/', component: Login, exact: true },
];
