import styled from 'styled-components';
import colors from '../../styles/colors';

export const Fundo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  @media only screen and (max-width: 640px) {
    width: 100%;
    height: 100%;
    display: grid;
    padding: 20px;
    background-color: ${colors.primaria};
  }
`;

export const BlocoEsquerdo = styled.div`
  width: 50%;
  height: 100vh;
  background-color: ${colors.primaria};
  padding: 20px;
  align-items: center;
  align-content: center;
  display: flex;
`;

export const BlocoDireito = styled.div`
  width: 50%;
  height: 100vh;
  background-color: ${colors.secundaria};
  padding: 40px;

  align-items: center;
  align-content: center;
  display: flex;
`;

export const Imagem = styled.img`
  width: 100%;
`;
