import React from 'react';
import { Form, message, Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { any, func, objectOf } from 'prop-types';
import { Campo } from '../../layout';
import { useApi, useUrl } from '../../../hooks';
import { onFinish } from '../functions';

import { Botao, Input, Container, Titulo } from './styled';

const AceitarConviteForm = ({ history, afterSubmit }) => {
  const [form] = Form.useForm();
  const { pegarValorQuery } = useUrl(history);
  const { alterar } = useApi();
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );
  const aceitarConvite = async ({ senha, confirmacaoSenha }) => {
    if (senha === confirmacaoSenha) {
      const { data } = await alterar(
        '/convites/aceitar',
        {
          senha,
          token: pegarValorQuery('token'),
        },
        'Convite aceito!',
      );
      return data;
    }
    return message.error('Ambas as senhas deverão ser idênticas!');
  };

  return (
    <>
      <Container>
        <Titulo>Criar Senha</Titulo>
        <Form
          onFinish={values =>
            onFinish(
              {
                ...values,
                objectUid: 1,
              },
              null,
              aceitarConvite,
              afterSubmit,
              form.resetFields,
            )
          }
          form={form}
          layout="vertical"
          hideRequiredMark
        >
          <Spin spinning={loading}>
            <Campo name="senha" fluido obrigatorio label="Crie uma Senha">
              <Input type="password" placeholder="********" />
            </Campo>
            <Campo
              name="confirmacaoSenha"
              fluido
              obrigatorio
              label="Confirme a Senha"
            >
              <Input type="password" placeholder="********" />
            </Campo>
            <Botao type="submit" htmlType="submit">
              Aceitar convite
            </Botao>
          </Spin>
        </Form>
      </Container>
    </>
  );
};

AceitarConviteForm.propTypes = {
  history: objectOf(any).isRequired,
  afterSubmit: func,
};

AceitarConviteForm.defaultProps = {
  afterSubmit: null,
};

export default AceitarConviteForm;
