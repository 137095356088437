import React from 'react';
import { any, func, object, objectOf, oneOfType, string } from 'prop-types';
import {
  ItemContainer,
  DataContainer,
  Item,
  CardHeaderContainer,
  Button,
  Logo,
} from './styles';

const Card = ({ logo, header, content, button, onClick }) => {
  return (
    <ItemContainer onClick={onClick} pointer={`${!!onClick}`}>
      <DataContainer>
        {logo ?? <Logo />}
        <CardHeaderContainer>
          {typeof header === 'string' ? <h2>{header}</h2> : header}
        </CardHeaderContainer>
        <Item>{content}</Item>
      </DataContainer>
      {button && (
        <Button type="primary" onClick={button?.onClick}>
          {button?.text}
        </Button>
      )}
    </ItemContainer>
  );
};

Card.propTypes = {
  logo: objectOf(any),
  header: oneOfType([string, object]),
  content: objectOf(any),
  button: objectOf(any),
  onClick: func,
};

Card.defaultProps = {
  logo: null,
  header: null,
  content: null,
  onClick: null,
  button: null,
};

export default Card;
