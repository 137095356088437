import background1 from './background1.jpg';
import background2 from './background2.jpg';
import background3 from './background3.jpg';
import background4 from './background4.jpg';
import background5 from './background5.jpg';
import background6 from './background6.jpg';
import background7 from './background7.jpg';
import background8 from './background8.jpg';
import background9 from './background9.jpg';
import background10 from './background10.jpg';
import background11 from './background11.jpg';
import background12 from './background12.jpg';
import background13 from './background13.jpg';
import background14 from './background14.jpg';
import background15 from './background15.jpg';
import background16 from './background16.jpg';
import background17 from './background17.jpg';
import background18 from './background18.jpg';

export const getImagens = () => [
  { img: background1 },
  { img: background2 },
  { img: background3 },
  { img: background4 },
  { img: background5 },
  { img: background6 },
  { img: background7, escuro: true },
  { img: background8 },
  { img: background9 },
  { img: background10 },
  { img: background11, escuro: true },
  { img: background12, escuro: true },
  { img: background13, escuro: true },
  { img: background14, escuro: true },
  { img: background15, escuro: true },
  { img: background16, escuro: true },
  { img: background17 },
  { img: background18, escuro: true },
];
