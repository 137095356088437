import React, { useEffect, useState } from 'react';
import { any, objectOf } from 'prop-types';
import { Header, Tabela } from '../../../components/layout';
import Descritor from '../../../components/layout/Descritor';
import maps from '../../../assets/icones/google-maps.png';
import { useApi } from '../../../hooks';
import { EnderecoContainer } from '../../MeusContratos/Detalhes/styles';
import { montarHref } from '../../../utils';
import { PAPEIS } from '../../../components/layout/Tabela/nomesCabecalhos';
import { EXCLUIR } from '../../../components/layout/Tabela/nomesAcoes';
import { DetalhesContainer } from '../../styles';

/* eslint-disable react/prop-types */
const detalhes = [
  {
    titulo: 'Dados de Identificação',
    itens: [
      { nome: 'CNPJ', propriedade: 'cnpj' },
      { nome: 'Razão Social', propriedade: 'razaoSocial' },
      { nome: 'Nome Fantasia', propriedade: 'nomeFantasia' },
    ],
  },

  {
    titulo: 'Contatos',
    naoTemSe: ({ celular, telefone, email }) => !celular && !telefone && !email,
    itens: [
      {
        nome: 'Celular',
        propriedade: 'celular',
      },
      {
        nome: 'Telefone',
        propriedade: 'telefone',
      },
      {
        nome: 'E-mail',
        custom: ({ email }) => email && <a href={`mailto:${email}`}>{email}</a>,
      },
    ],
  },

  {
    titulo: 'Endereço',
    itens: [
      { nome: 'Município', propriedade: 'municipio' },
      {
        nome: ({
          enderecoLogradouro: logradouro,
          enderecoNumero: numero,
          enderecoComplemento: complemento,
          enderecoBairro: bairro,
          enderecoCep: cep,
          municipio,
        }) => (
          <EnderecoContainer>
            <h4>Endereço</h4>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://www.google.com/maps/search/${montarHref(
                logradouro,
              )}${montarHref(numero)}${montarHref(complemento)}${montarHref(
                bairro,
              )}${montarHref(municipio)}${montarHref(cep)}`}
            >
              Abrir no Google Maps
            </a>
            <img src={maps} alt="Google Maps" />
          </EnderecoContainer>
        ),
        custom: ({
          enderecoLogradouro: logradouro,
          enderecoNumero: numero,
          enderecoComplemento: complemento,
          enderecoBairro: bairro,
          enderecoCep: cep,
        }) => (
          <>
            {`${logradouro}, ${numero || ''}, ${complemento || ''} ${bairro}`}
            <br />
            CEP {cep}
          </>
        ),
      },
    ],
  },
];
/* eslint-enable react/prop-types */

const DetalhesCnpj = ({ history, match }) => {
  const { id } = match.params;
  const { buscar, deletar } = useApi();
  const [cnpj, setCnpj] = useState({});
  const [papeis, setPapeis] = useState([]);

  useEffect(() => {
    const carregarCnpj = async () => {
      const { data } = await buscar(`/cnpjs/${id}`);
      setCnpj(data);
    };
    const carregarPapeis = async () => {
      const { data } = await buscar(`/papeisusuarios/cnpjs/${id}`);
      setPapeis(data);
    };
    carregarCnpj();
    carregarPapeis();
  }, [buscar, id]);

  // const revogarPapel = () => {};

  const excluirPapel = async ({ uid, papel }) => {
    await deletar(
      `/papeis/${uid}`,
      `Papel ${papel ? papel?.nome : ''} deletado com sucesso!`,
      () => setPapeis(papeis.filter(({ uid: papelUid }) => papelUid !== uid)),
    );
  };

  return (
    <>
      <Header
        onBack={() => history.goBack()}
        titulo={cnpj?.apelido}
        classe="CNPJ"
      >
        <Descritor objeto={cnpj} propriedades={detalhes} />
      </Header>

      <DetalhesContainer>
        <Tabela
          titulo
          cabecalho={PAPEIS}
          dados={papeis}
          acoes={[
            /*  {
              titulo: REVOGAR,
              onClick: revogarPapel,
              confirmacao: true,
            }, */
            {
              titulo: EXCLUIR,
              onClick: excluirPapel,
              confirmacao: true,
            },
          ]}
        />
      </DetalhesContainer>
    </>
  );
};

DetalhesCnpj.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default DetalhesCnpj;
