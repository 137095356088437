import styled from 'styled-components';
import colors from '../../../styles/colors';

export const SectionContainer = styled.div`
  border-radius: 2px;
  padding: 0px;
  margin-bottom: 10px;
`;

export const Titulo = styled.span`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 30px;
  color: ${colors.primaria_fundo_claro};
`;

export const ChildrenContainer = styled.div`
  margin-top: ${({ titulo, semMargem }) => {
    if (semMargem !== 'true') {
      return titulo?.length > 0 ? '20px' : '43px';
    }
    return null;
  }};
`;
